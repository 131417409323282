import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('integration/sap/tasks?page=' + page, {params});
    },
    task_log(id) {
        return Service().get('integration/sap/tasks/log/'+id);
    },
    synchron(id) {
        return Service().post('integration/sap/tasks/'+id);
    }
}