<template>
    <div>
        <div v-if="authUserPermission['sap-sync-task-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('sap_synchron_task') }}</h3>
                            </div>
                            <div class="col-5">
                                <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_sap_synchron_task')" v-on:keyup="filter"/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('sap_sync_id')" :prop="tt('sap_sync_id')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.sap_sync_id}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('connection')" :prop="tt('connection')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.sap_conn['connection']}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('key')" :prop="tt('key')" min-width="150px" sortable>
                                <template v-slot="{row}">
                                    {{row.key}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('requested_by')" :prop="tt('requested_by')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.requested_by}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('requested_at')" :prop="tt('requested_at')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.requested_at}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('executed_at')" :prop="tt('executed_at')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.executed_at}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('done_at')" :prop="tt('done_at')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.done_at}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="120px" sortable>
                                <template v-slot="{row}">
                                    <label class="badge badge-primary" v-if="row.status == '0'">WAITING</label>
                                    <label class="badge badge-success" v-else-if="row.status == '1'">SUCCESS</label>
                                    <label class="badge badge-danger" v-else="row.status == '-1'">FAILED</label>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="300">
                                <template v-slot="{row}">
                                    <div class="row">
                                        <div class="col text-right">
                                            <base-button size="sm" type="primary" @click="synchron(row.id)" v-if="row.status == '0'">
                                                <span v-if="btnSync.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                                <span v-else>{{ tt('synchron') }}</span>
                                            </base-button>
                                            <base-button size="sm" type="primary" @click="synchron(row.id)" v-if="row.status == '-1'" :disabled="btnSync.onLoading">
                                                <span v-if="btnSync.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                                <span v-else>{{ tt('re_synchron') }}</span>
                                            </base-button>
                                            <base-button size="sm" type="warning" @click="edit(row.key)" v-if="row.status == -1">{{ tt('edit') }}</base-button>
                                            <base-button size="sm" type="dark" @click="task_log(row.id)">{{ tt('view_log') }}</base-button>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <modal :show.sync="form.show" size="lg">
                <template slot="header">
                    <h4 class="modal-title">{{form.title}}</h4>
                </template>
                <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="sapSyncLog.data" v-if="!onLoad">
                <el-table-column :label="tt('sap_sync_task_id')" :prop="tt('sap_sync_task_id')" min-width="170px" sortable>
                    <template v-slot="{row}">
                        {{row.sap_sync_task_id}}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('type')" :prop="tt('type')" min-width="170px" sortable>
                    <template v-slot="{row}">
                        {{row.type}}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('message')" :prop="tt('message')" min-width="150px" sortable>
                    <template v-slot="{row}">
                        {{row.message}}
                    </template>
                </el-table-column>
                <el-table-column :label="tt('date')" :prop="tt('date')" min-width="190px" sortable>
                    <template v-slot="{row}">
                        {{row.timestamp}}
                    </template>
                </el-table-column>
            </el-table>
            </modal>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapSyncTask from '@/services/sap/sapSyncTask.service';
    import draftList from "@/services/material/draftList.service";

    export default {        
        data() {
            return { 
                onLoad: true, 
                btnSync: {
                    onLoading: false
                },    
                loadTimeout: null,                 
                form: {
                    add: true,
                    title: this.tt('add_sap_synchron_task'),
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                    log:[]
                },        
                sapSyncTask: {}, 
                sapSyncLog: {
                    data: [],
                }, 
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'synchron':
                            this.synchron(command.data);
                        break;
                    case 'task_log':
                            this.task_log(command.data);
                        break;
                    case 'edit':
                            this.edit(command.data);
                        break;
                    default:
                        break;
                }
            },
            get() { 
                let context = this;               
                Api(context, sapSyncTask.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data  = response.data.data.data.data;  
                    context.table.page  = response.data.data.data;  
                    context.onLoad      = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            synchron(id) {
                this.confirmDialog(this.tt('confirm_synchron')).then((result) => {
                    let context = this;
                    if (result.value) {
                        context.btnSync.onLoading = true;
                        Api(context, sapSyncTask.synchron(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).onError(function(error) {                    
                           if (error.response.data.message) {
                                context.$notify({
                                    message: error.response.data.message,                  
                                    type: 'danger',
                                    verticalAlign: 'bottom', 
                                    horizontalAlign: 'left'
                                });           
                            }
                        }).onFinish(function(){
                            context.btnSync.onLoading = false;
                        }).call()
                    }
                })
            },
            task_log(id) {
                let context = this;               
                Api(context, sapSyncTask.task_log(id)).onSuccess(function(response) {
                    context.sapSyncLog.data = response.data.data;
                    context.form.add        = false;
                    context.form.title      = context.tt('synchron_task_log');
                    context.form.show       = true;                      
                })
                .call() 
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            edit(key) {
                let context = this;               
                Api(context, draftList.get_emcr_from_item_mcr(key)).onSuccess(function(response) {
                    context.$router.push('/material/mcr-form-detail/'+response.data.data[0].form_type+'/'+response.data.data[0].mcr_code+'/'+key+'/'+response.data.data[0].token+'/'+'edit') ;               
                })
                .call() 

              
            },
        }   
    };
</script>
<style></style>
